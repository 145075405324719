// helper functions
const MathUtils = {
    // map number x from range [a, b] to [c, d]
    map: (x, a, b, c, d) => (x - a) / (b - a) * (d - c) + c,
    // linear interpolation
    lerp: (a, b, n) => (1 - n) * a + n * b,
    //clamp: if num in range, return num, else nearest Number in the range
    clamp: (num, a, b) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))

    // Lerp Map x: 
    //          den Abstand vom oberen Dokumentenrand abzüglich den Scrollfortschritt
    //          wenn das gleich windows.height, fangen wir an das Bild zu sehen
    //          a: einfach die Viewport höhe
    //          b: negative Höhe des Bildwrappers (sichtbares Bild)
    //          c: mindestWert (vorgegebene Transition negativ 80px)
    //          d: MaxWert (vorgegebene Transition negativ 80px)
};

export { MathUtils }