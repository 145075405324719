import { gsap } from 'gsap';

import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import SimplexNoise from 'simplex-noise';
import { spline } from '@georgedoescode/spline';
import { MathUtils } from '../../utils/MathUtils'


gsap.registerPlugin(DrawSVGPlugin);
// gsap.registerPlugin(MorphSVGPlugin);

const defOptions = {
    backBtnNavi: false
}

class NaviMorph {
    constructor(options) {
        this.options = { ...defOptions, ...options }
        this.DOM = { $hamburgerBtn: document.querySelector('.hamburger-btn') }
        this.DOM.$closeBtn = document.querySelector('.close-btn')

        this.DOM.$toggleNavBtnWrp = document.querySelector('.mob-nav-toggler')

        this.DOM.$backBtn = document.querySelector('.back-btn')
        // this.DOM.$canvasContainer = document.getElementById('canvas-container')
        this.DOM.$navCanvas = document.querySelector('.blende-navi')
        this.DOM.$toggleNavLinks = [...this.DOM.$navCanvas.querySelectorAll('a')]
        this.DOM.$bgImageWrp = this.DOM.$navCanvas.querySelector('.img-wrp')


        this.DOM.$morphWrap = this.DOM.$navCanvas.querySelector('.blob-wrp')
        this.DOM.$morphSVG = this.DOM.$morphWrap.querySelector('svg')
        this.DOM.$morphPath = this.DOM.$morphSVG.querySelector('#morphPath')

        this.DOM.$bgImagePathes = [...this.DOM.$navCanvas.querySelectorAll('path')]






        this.simplex = new SimplexNoise();
        this.anchors = []
        this.boundaries = { width: undefined, height: undefined, dynamic: undefined }



        if (this.options.backBtnNavi) {
            this.showBackNav()
        }

        this.init()

    }

    createPoints = () => {

        // how many points do we need
        const numPoints = 8;
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / numPoints;
        // the radius of the circle
        const rad = 75;

        for (let i = 0; i <= numPoints - 1; i++) {
            // x & y coordinates of the current point
            const theta = i * angleStep;
            const x = 100 + Math.cos(theta) * rad;
            const y = 100 + Math.sin(theta) * rad;

            // store the point
            this.anchors.push({
                x: x,
                y: y,
                /* we need to keep a reference to the point's original {x, y} coordinates 
                for when we modulate the values later */
                originX: x,
                originY: y,
                // // more on this in a moment!
                noiseOffsetX: Math.random() * 1000,
                noiseOffsetY: Math.random() * 1000,
            });
        }

    }

    init = () => {
        this.createPoints()

        gsap.set(this.DOM.$morphPath, { x: 0, y: 0 })
        this.addEvents();
        this.setBoundaries();

        this.render();
    }

    noise = (x, y) => {
        return this.simplex.noise2D(x, y)
    }

    updateCoordinates = () => {
        const noiseStep = .005


        for (let index = 0; index < this.anchors.length - 1; index++) {
            const point = this.anchors[index]
            // Noise Point between -1 and 1
            const nX = this.noise(point.noiseOffsetX, point.noiseOffsetX);
            const nY = this.noise(point.noiseOffsetY, point.noiseOffsetY);

            const x = MathUtils.map(nX, -1, 1, point.originX - this.boundaries.dynamic, point.originX + this.boundaries.dynamic);
            const y = MathUtils.map(nY, -1, 1, point.originY - this.boundaries.dynamic, point.originY + this.boundaries.dynamic);

            point.x = x;
            point.y = y;

            // progress the point's x, y values through "time"
            point.noiseOffsetX += noiseStep;
            point.noiseOffsetY += noiseStep;
        }
    }

    render = () => {
        this.updateCoordinates()

        let path = document.querySelector("#morphPath");
        path.setAttribute("d", spline(this.anchors, 1, true));
    }


    addEvents() {
        this.DOM.$hamburgerBtn.addEventListener('click', this.openNavi)
        this.DOM.$closeBtn.addEventListener('click', this.closeNavi)
        window.addEventListener('resize', this.setBoundaries)
    }
    setBoundaries = () => {
        this.boundaries.width = window.innerWidth;
        this.boundaries.height = window.innerHeight;
        this.boundaries.dynamic = Math.floor((-this.DOM.$morphWrap.clientWidth * .01) + 20)

    }


    openNavi = () => {
        const DOM = this.DOM;
        this.naviOpen = true;

        DOM.$toggleNavBtnWrp.dataset.navOpen = 1;

        gsap.ticker.add(this.render)
        gsap.timeline({})
            .set(DOM.$toggleNavLinks, { yPercent: 100 })
            .set(DOM.$bgImageWrp, { x: -this.boundaries.width })
            .set(DOM.$morphPath, { drawSVG: 0 })
            // .set(DOM.$bgImagePathes, { drawSVG: 0 })
            .set(DOM.$navCanvas, {
                autoAlpha: 1
            })
            .to(DOM.$bgImageWrp, {
                x: 0,
                duration: .35,
            })
            .to(DOM.$morphPath, { drawSVG: '100%' })
            .to(DOM.$hamburgerBtn, { autoAlpha: 0, duration: .25 })
            .to(DOM.$toggleNavLinks, { yPercent: 0, duration: .5, stagger: .1 }, "-=.35")
            .to(DOM.$closeBtn, { autoAlpha: 1, duration: .25, pointerEvents: 'all' })

    }
    closeNavi = () => {
        gsap.ticker.remove(this.render)
        const DOM = this.DOM;
        this.naviOpen = false;
        DOM.$toggleNavBtnWrp.dataset.navOpen = 0;
        const tl = gsap.timeline()

            .to(DOM.$closeBtn, { autoAlpha: 0, duration: .25, pointerEvents: 'none' })
            .to(DOM.$morphPath, { drawSVG: '100% 100%' })
            .to(DOM.$toggleNavLinks, { yPercent: 100, duration: .5, stagger: .025 })
            .to(DOM.$bgImageWrp, { x: -this.boundaries.width, duration: .75, }, "-=.25")
            .to(DOM.$hamburgerBtn, { autoAlpha: 1, duration: .25 })
            .set(DOM.$bgImageWrp, { x: -this.boundaries.width })
            .set([DOM.$navCanvas, DOM.$closeBtn], {
                autoAlpha: 0
            })

        return tl
    }



    showBackNav = () => {
        const tl = gsap.timeline({ delay: 1.25 })
            .to(this.DOM.$hamburgerBtn, { autoAlpha: 0, pointerEvents: "none", duration: .25 })
        if (this.DOM.$backBtn) {
            tl.to(this.DOM.$backBtn, { autoAlpha: 1, pointerEvents: 'all', duration: .25 })
        }
    }

    showHamburgerNav = () => {
        const tl = gsap.timeline({ delay: 1.25 })
        if (this.DOM.$backBtn) {
            tl.to(this.DOM.$backBtn, { autoAlpha: 0, pointerEvents: "none", duration: .25 })
        }
        tl.to(this.DOM.$hamburgerBtn, { autoAlpha: 1, pointerEvents: 'all', duration: .25 })
    }

}

export { NaviMorph }