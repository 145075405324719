const mapping = [
    { de: '/index.html', en: '/en/index.html' },
    { de: '/', en: '/en/' },
    { de: '/about.html', en: '/en/about.html' },
    { de: '/agb.html', en: '/en/gtc.html' },
    { de: '/datenschutz.html', en: '/en/privacy.html' },
    { de: '/design.html', en: '/en/design.html' },
    { de: '/impressum.html', en: '/en/imprint.html' },
    { de: '/kontakt.html', en: '/en/contact.html' },
    { de: '/ole-guellich.html', en: '/en/ole-guellich.html' },
    { de: '/lucas-hoyer.html', en: '/en/lucas-hoyer.html' },
    { de: '/operating.html', en: '/en/operating.html' },
    { de: '/pre-production.html', en: '/en/pre-production.html' },
    { de: '/raphael-grebenstein.html', en: '/en/raphael-grebenstein.html' },
]
const lang = document.documentElement.lang

const switchLanguageLinks = (nextData) => {
    const switchLinks = [...document.querySelectorAll('a.lang-switch')];
    const path = nextData.url.path;

    if (lang === 'de') {
        const result = mapping.find(({ de }) => de == path)
        switchLinks.forEach(link => link.href = result.en)

    } else {
        const result = mapping.find(({ en }) => en == path)
        switchLinks.forEach(link => link.href = result.de)
    }

}

export { switchLanguageLinks }