import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);

//darkThemeInContent
export default (nextBarbaWrapper) => {
    const heading = nextBarbaWrapper.querySelector('h1');
    const subTitle = nextBarbaWrapper.querySelector('.head-row h3.hand')
    // const startImg = nextBarbaWrapper.querySelector('#startImg');

    new SplitText(heading, { type: "words, chars", charsClass: "chars" });

    const tl = gsap.timeline()
        .from('.chars', {
            yPercent: 80,
            opacity: 0,
            duration: 2,
            stagger: .025,
            ease: "power4.out"
        })
    return tl;
}