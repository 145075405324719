import { MathUtils } from '../../utils/MathUtils'

class SliderImgParallax {
    constructor(sliderEl) {
        this.DOM = { wrapper: sliderEl }
        this.DOM.image = sliderEl.querySelector('img');
        this.state = {
            currentX: 0,
            targetX: 0,
            min: 0,
            max: 0,
            totScrollMin: 0,
            totScrollMax: 0

        }
        this.opts = {
            overflow: .5,
            imgRatio: 0.666666,
            ease: .1
        }
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this.isVisible = entry.intersectionRatio > 0;
            });
        });
        this.observer.observe(this.DOM.wrapper)
        this.setSizes()
        this.setInitialPositions()
        this.addEvents()
    }
    setSizes() {
        const state = this.state
        this.DOM.image.style.width = this.DOM.wrapper.clientWidth * (1 + this.opts.overflow) + 'px';
        this.DOM.image.style.left = -this.DOM.wrapper.clientWidth * (this.opts.overflow) * 0.5 + 'px';
        this.DOM.image.style.top = -this.DOM.wrapper.clientHeight * (this.opts.overflow) * 0.5 + 'px';
        state.max = this.DOM.wrapper.clientWidth * this.opts.overflow * .5
        state.min = -state.max;
        state.totScrollMin = window.innerWidth;
        state.totScrollMax = -this.DOM.wrapper.clientWidth;
    }
    setInitialPositions() {
        const state = this.state;
        this.update();
        state.currentX = state.targetX;
        this.DOM.image.style.transform = `translate3d(${-state.targetX}px, 0,0)`
    }


    update() {
        const state = this.state;
        const { left } = this.DOM.wrapper.getBoundingClientRect();

        state.targetX = MathUtils.map(left, state.totScrollMin, state.totScrollMax, state.min, state.max);
        state.currentX = MathUtils.lerp(state.currentX, state.targetX, this.opts.ease);
    }

    render() {
        if (this.isVisible) {
            this.update()
            this.DOM.image.style.transform = `translate3d(${-this.state.currentX}px, 0,0)`
        }
    }
    addEvents() {
        window.addEventListener('resize', this.resize);
    }
    removeEvents() {
        window.removeEventListener('resize', this.resize)
    }
    destroy() {
        this.removeEvents()
        this.observer.disconnect();
    }
    resize = () => {
        this.setSizes()
        this.setInitialPositions()
    }

}

export { SliderImgParallax }