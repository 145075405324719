import { gsap } from 'gsap';


//blende Out
export default ({ next }) => {


    const tl = gsap.timeline()
        // .to('#blende-logo', { opacity: 0, duration: 2 })
        .to('#canvas-container .inner-blende', {
            yPercent: 100,
            delay: .5,
            duration: .75,
            stagger: .1,
        }, "-=1.5")

        .set('#canvas-container', {
            autoAlpha: 0,
        })
        .set('#canvas-container .blende', {
            yPercent: 0,
            backgroundColor: '',
            autoAlpha: 0,
            yPercent: -100,
        })

    return tl;
}