import { gsap } from 'gsap';
// import { SplitText } from "gsap/SplitText";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);


//darkThemeInContent
export default (nextBarbaWrapper) => {

    const preProdLogoSvg = nextBarbaWrapper.querySelector('.single-page-logo');
    const stroke1 = preProdLogoSvg.querySelector('#pre-prod-stroke1');
    const stroke2 = preProdLogoSvg.querySelector('#pre-prod-stroke2');
    const letters = [...preProdLogoSvg.querySelectorAll('.letter')]

    gsap.set('header #logo', { display: 'block', opacity: 0 })
    gsap.set(stroke1, { drawSVG: 0, opacity: 0  })
    gsap.set(stroke2, { drawSVG: 0, opacity: 0  })
    gsap.set(letters, { opacity: 0 })

    const tl = gsap.timeline({
        onStart() {

        }
    })
        .to(letters, { opacity: 1, duration: 1, stagger: .1, ease: 'linear' })
        .to(stroke1, { opacity: 1, drawSVG: '100%', duration: 1.75 }, "=-1")
        .to(stroke2, { opacity: 1, drawSVG: '100%', duration: 1.75 }, "<")
        .to('header #logo', { opacity: 1 })
    return tl;
}