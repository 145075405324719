import barba from '@barba/core';
import { gsap } from 'gsap';

import './services/font-service';

import { updateCopyText } from './utils/utils';
import './styles/main.scss';

import { Page } from './pages/Page';
// Boilerplate Components

import { NaviMorph } from './ui-components/naviMorph/NaviMorph';
import { ScrollPreventer } from './ui-components/scrollPreventer/scrollPreventer';

import { switchLanguageLinks } from './services/switchLanguageLinks'

// Animations
import {
    awaitAllImages, blendeInAnimation, blendeOutAnimation,
    preLoaderAnim, aboutPageContentIn, homePageContentIn,
    leistungsPageContentIn, metaPageContentIn, modalPageContentIn
} from './animations/animations';

let pageComponents = { homePage: undefined, aboutPage: undefined, galleryPage: undefined, metaPage: undefined, modalPage: undefined };

let pageInAnimations = { aboutPage: aboutPageContentIn, homePage: homePageContentIn, leistungsPage: leistungsPageContentIn, metaPage: metaPageContentIn, modalPage: modalPageContentIn }
//let pageInAnimations = { aboutPage: leistungsPageContentIn, homePage: homePageContentIn, leistungsPage: leistungsPageContentIn, metaPage: metaPageContentIn, modalPage: modalPageContentIn }

// Init Boilerplate Components
const navi = new NaviMorph({ backBtnNavi: false });

let scrollPreventer = new ScrollPreventer();

const createPageComponent = (nextData, options) => {
    const { backBtnNavi } = options
    if (backBtnNavi) { navi.showBackNav() }
    else { navi.showHamburgerNav() }
    pageComponents[nextData.namespace] = new Page(nextData, options)
}
const destroyPageComponent = (currentData) => {
    scrollPreventer.disableScroll()
    pageComponents[currentData.namespace].destroy();
    pageComponents[currentData.namespace] = undefined;
}


const enterTransition = async (data) => {
    console.log('enter transition')
    await awaitAllImages(data)

    window.scrollTo(0, 0)
    const tl = gsap.timeline({
        onComplete() {
            pageComponents[data.next.namespace] && pageComponents[data.next.namespace].smoothScroll.on('app.js 51')
            pageComponents[data.next.namespace].refreshSmoothScrollItemsPositions();
            scrollPreventer.enableScroll()
        }
    })
    if (navi.naviOpen) { tl.add(navi.closeNavi()) }
    if (!navi.naviOpen) { tl.add(blendeOutAnimation(data)) }
    tl.add(pageInAnimations[data.next.namespace](data.next.container))
}

barba.init({
    debug: true,
    views: [
        {
            namespace: 'homePage',
            beforeEnter(data) {
                switchLanguageLinks(data.next)
                createPageComponent(data.next, {
                    backBtnNavi: false, bgSVGShow: true,
                    bgSVGAnimate: true,
                })
            },
            beforeLeave(data) { destroyPageComponent(data.current) }
        },
        {
            namespace: 'leistungsPage',
            beforeEnter(data) {
                switchLanguageLinks(data.next)
                createPageComponent(data.next, {
                    backBtnNavi: false,
                    bgSVGShow: true,
                    bgSVGAnimate: true,
                })
            },
            beforeLeave(data) { destroyPageComponent(data.current) }
        },
        {
            namespace: 'modalPage',
            beforeEnter(data) {
                switchLanguageLinks(data.next)
                createPageComponent(data.next, {
                    backBtnNavi: true,
                    bgSVGShow: true,
                    bgSVGAnimate: true,
                })
            },
            beforeLeave(data) { destroyPageComponent(data.current) }
        },
        {
            namespace: 'aboutPage',

            beforeEnter(data) {
                switchLanguageLinks(data.next)
                createPageComponent(data.next, {
                    scrollDownBtnShowDelay: 8000,
                    scrollDownBtnColor: '#ffffff',
                    bgSVGShow: true,
                    bgSVGAnimate: true,
                })
            },  
            beforeLeave(data) { destroyPageComponent(data.current) }
        },
        {
            namespace: 'metaPage',
            beforeEnter(data) {
                switchLanguageLinks(data.next)
                createPageComponent(data.next, {
                    bgSVGShow: true,
                    bgSVGAnimate: true,
                    bgSVGStrokeColor: '#dc00fe'
                })
            },
            beforeLeave(data) { destroyPageComponent(data.current) }
        },

    ],

    transitions: [{
        name: 'homePage-transition',
        to: { namespace: ['homePage'] },
        once() { },
        async leave(data) {
            if (!navi.naviOpen) await blendeInAnimation('#252525');
        },
        async enter(data) {
            await enterTransition(data)
        },
        // async after() {
        //     pageComponents[data.next.namespace].refreshSmoothScrollItemsPositions();
        // }
    },
    {
        name: 'leistungsPage-transition',
        to: { namespace: ['leistungsPage'] },
        once() { },
        async leave(data) {
            if (!navi.naviOpen) await blendeInAnimation('#252525');
        },
        async enter(data) {
            await enterTransition(data)
        },
    },
    {
        name: 'metaPage-transition',
        to: { namespace: ['metaPage'] },
        once() { },
        async leave(data) {
            if (!navi.naviOpen) await blendeInAnimation('#252525');
        },
        async enter(data) {

            await enterTransition(data)
        },
    },
    {
        name: 'modalPage-transition',
        to: { namespace: ['modalPage'] },
        once() { },
        async leave(data) {
            if (!navi.naviOpen) await blendeInAnimation('#252525');
        },
        async enter(data) {
            await enterTransition(data)
        }
    },
    {
        name: 'aboutPage-transition',
        to: { namespace: ['aboutPage'] },
        once() { },
        async leave(data) {
            if (!navi.naviOpen) await blendeInAnimation('#252525');
        },
        async enter(data) {
            await enterTransition(data)
        }
    }
    ]
});


// barbaHooks ONCE Preloader & co
barba.hooks.once(async (data) => {
    updateCopyText()
    await preLoaderAnim.in();
    await awaitAllImages(data);

    const namespaceName = data.next.namespace;
    const masterTL = gsap.timeline({

        onComplete() {
            pageComponents[namespaceName] && pageComponents[namespaceName].smoothScroll.on()
            scrollPreventer.enableScroll()
        }
    })
        .add(preLoaderAnim.out())
        .add(pageInAnimations[data.next.namespace](data.next.container))

})

// barba.hooks.afterEnter(() => {
//     console.log('afterEnter')
// })