import { MathUtils } from '../../utils/MathUtils'
import { gsap } from 'gsap';

import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);

class MouseFollower {
    constructor(activationElements) {
        this.DOM = {
            cursor: document.getElementById('fsi-cursor'),
            activationElements: activationElements
        }
        if (this.DOM.activationElements) {
            this.renderedStyles = {
                x: 0,
                y: 0,
                previousX: 0,
                previousY: 0
            }
            this.bindMethods();
            this.initEvents();
        }
    }
    bindMethods() {
        ['handleMouseEnter', 'handleMouseLeave', 'handleMouseMove',]
            .forEach((fn) => this[fn] = this[fn].bind(this))
    }
    handleMouseEnter(e) {
        this.enableImg('MORE')

    }
    handleMouseLeave(e) {
        this.disableImg()
    }
    handleMouseMove(e) {
        this.update(e.clientX, e.clientY)
    }

    enableImg(text) {
        this.DOM.cursor.innerHTML = text ? text : 'VIEW';
        new SplitText(this.DOM.cursor, { type: "chars", charsClass: "cursorchars" });
        const tl = gsap.timeline()
            .set(this.DOM.cursor, { display: "inline-block" })
            .from('.cursorchars', {
                opacity: 0,
                x: 175,
                stagger: .1,
                duration: .75,
                ease: 'Power4.easeOut'
            })

        // a nice tween here // ggf different Textes;
        this.active = true;
        this.DOM.cursor.style.opacity = 1;

    }
    disableImg() {
        this.DOM.cursor.innerHTML = '';
        this.DOM.cursor.style.display = 'none';
        this.DOM.cursor.style.opacity = 0;
    }

    update(x, y) {
        this.renderedStyles.x = x;
        this.renderedStyles.y = y;
    }
    render() {
        this.renderedStyles.previousX = MathUtils.lerp(this.renderedStyles.previousX, this.renderedStyles.x, .15);
        this.renderedStyles.previousY = MathUtils.lerp(this.renderedStyles.previousY, this.renderedStyles.y, .15);
        this.DOM.cursor.style.transform = `translate3d(${this.renderedStyles.previousX + 25}px,${this.renderedStyles.previousY - 25}px,0)`;
    }

    initEvents() {
        document.addEventListener('mousemove', this.handleMouseMove)
        this.DOM.activationElements.forEach(element => {
            element.addEventListener('mouseenter', this.handleMouseEnter)
            element.addEventListener('mouseleave', this.handleMouseLeave)
        })

    }

    destroy() {
        if (this.DOM.activationElements) {
            this.disableImg();
            this.DOM.activationElements.forEach(element => {
                this.DOM = undefined;
                document.removeEventListener('mousemove', this.handleMouseMove)
                element.removeEventListener('mouseenter', this.handleMouseEnter)
                element.removeEventListener('mouseleave', this.handleMouseLeave)
            })
        }
    }
    tick() {
        this.render()
    }

}

export { MouseFollower }


// TODOS:
// Allways Update Cursor Position
// Timelines für einanimation