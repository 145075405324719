import { gsap } from 'gsap';


//blendeUp
export default (blendColor) => {
    const tl = gsap.timeline()
        .set('#canvas-container', {
            autoAlpha: 1,
        })
        .set('#canvas-container .blende', {
            yPercent: 100,
            autoAlpha: 1,
            yPercent: 0,
        })
        .set('#canvas-container .inner-blende', {
            yPercent: -100,
            autoAlpha: 0,
        })
        .to('.inner-blende', {
            yPercent: 0,
            duration: .75,
            stagger: .1,
            autoAlpha: 1
        })
    // .to('#blende-logo', { opacity: 0.15 })

    return tl
}