const imagesLoaded = require('imagesloaded');

export default ({ next }) => {
    const imagePromise = new Promise(resolve => {
        const imgLoad = imagesLoaded(next.container);
        imgLoad.on('always', (instance, img) => {
            resolve()
        })
    })
    return imagePromise;
}