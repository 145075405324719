import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);

class MySplitText {
    constructor(splitTexts) {
        this.DOM = { elems: [...splitTexts] }
        if (!this.DOM.elems) return;

        this.observer = new IntersectionObserver(entries => entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                if (!entry.isAnimated) {

                    this.animateLetters(entry.target)
                    entry.isAnimated = true
                }
            }
        }))

        this.DOM.elems.forEach(element => {
            this.observer.observe(element)
        });
        this.splitTexts()


    }
    splitTexts = () => {
        this.DOM.elems.forEach(elem => {
            new SplitText(elem, { type: "words, chars", wordsClass: 'words', charsClass: "chars" });
        });
        gsap.set('.split-text .chars', { opacity: 0.25 })

    }
    animateLetters = (entry) => {
        gsap.to(entry.querySelectorAll('.chars'), {
            opacity: 1,
            duration: .5,
            stagger: .021,
            delay: 0
        })
    }


}

export { MySplitText }