import { MathUtils } from '../../utils/MathUtils';
import { config } from '../../services/config';

const smoothImgWraps = [...document.querySelectorAll('.smoothyImgWrp')];

// Params: Text should be CSS Formatted to 850 px FontFamily Cinzel -> Max Distance is -17% + 17% Origin Text y Offset is 53%


class SVGMaskTextBlock {
    constructor(el, smoothScroll) {
        this.bindMethods();
        this.DOM = { el: el }
        if (!this.DOM.el) return
        this.DOM.image = this.DOM.el.querySelector('clipPath');
        this.smoothScroll = smoothScroll
        this.isVisible = undefined
        this.renderedStyles = {
            innerTranslationY: {
                ease: 0.05,
                current: 0,
                previous: 0,
                rounded: 0,
                // the maximum value to translate the image is set in a CSS variable (--overflow)
                // maxValue: parseInt(getComputedStyle(this.DOM.image).getPropertyValue('--overflow'), 10),
                maxValue: undefined,
                setValue: () => {
                    const maxValue = 17;
                    const minValue = -17;

                    // Lerp Map x: 
                    //          den Abstand vom oberen Dokumentenrand abzüglich den Scrollfortschritt
                    //          wenn das gleich windows.height, fangen wir an das Bild zu sehen
                    //          a: einfach die Viewport höhe
                    //          b: negative Höhe des Bildwrappers (sichtbares Bild)
                    //          c: mindestWert (vorgegebene Transition negativ 80px)
                    //          d: MaxWert (vorgegebene Transition negativ 80px)
                    //       

                    return Math.max(Math.min(MathUtils.map(this.props.top - smoothScroll.data.target, config.winHeight, -1 * this.props.height, minValue, maxValue), maxValue), minValue)

                }
            }
        }



        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this.isVisible = entry.intersectionRatio > 0;

            });

        });
        this.observer.observe(this.DOM.el);
        // this.setWrpSizeAndOverflow();
        this.update();
        this.addEvents();
        this.getSize = this.getSize;


        // this.setWrpSizeAndOverflow();
    }
    bindMethods() {
        ['resize']
            .forEach((fn) => this[fn] = this[fn].bind(this))
    }
    // setWrpSizeAndOverflow() {
    //     const ratio = parseFloat(this.DOM.el.dataset.irat, 10);
    //     const wrpHeight = parseInt((this.DOM.el.clientWidth / ratio), 10);
    //     const overflow = this.DOM.el.dataset.overflow ? this.DOM.el.dataset.overflow * wrpHeight : 0.1 * wrpHeight;
    //     this.DOM.el.style.height = wrpHeight + 'px';
    //     // this.DOM.image.style.setProperty('--overflow', wrpHeight * overflow + 'px')
    //     this.DOM.image.style.top = -overflow / 2 + 'px'
    //     this.DOM.image.style.height = wrpHeight + overflow * 1 + 'px'
    //     this.renderedStyles.innerTranslationY.maxValue = overflow;
    // }
    update() {
        this.getSize();
        // set initial value without interpolation
        this.renderedStyles.innerTranslationY.current = this.renderedStyles.innerTranslationY.previous = this.renderedStyles.innerTranslationY.setValue()
        // translate the image
        this.layout();
    }
    getSize() {
        if (!this.DOM.el) return
        const { height, top } = this.DOM.el.getBoundingClientRect();
        this.props = { height, top: this.smoothScroll.data.target + top }

    }
    addEvents() {
        window.addEventListener('resize', this.resize, { passive: true });
    }
    removeEvents() {
        window.removeEventListener('resize', this.resize, { passive: true });
    }
    destroy() {
        if (!this.DOM.el) return
        this.renderedStyles.innerTranslationY = null;
        this.renderedStyles = null;
        this.removeEvents()
    }



    resize() {
        this.update();
    }

    render() {

        // update the current and interpolated Values
        this.renderedStyles.innerTranslationY.current = this.renderedStyles.innerTranslationY.setValue();
        this.renderedStyles.innerTranslationY.previous = MathUtils.lerp(this.renderedStyles.innerTranslationY.previous, this.renderedStyles.innerTranslationY.current, this.renderedStyles.innerTranslationY.ease);
        this.layout();
    }

    layout() {
        // console.log('layout', this.renderedStyles.innerTranslationY)

        //without siblings
        this.DOM.image.style.transform = `translateY(${this.renderedStyles.innerTranslationY.previous}%)`;

        //withSiblings
        // this.DOM.el.querySelectorAll('img').forEach(image => {
        //     image.style.transform = `translate3d(0,${this.renderedStyles.innerTranslationY.previous}px,0)`;
        // })
    }
}

export {
    SVGMaskTextBlock
}