import { gsap } from 'gsap';
// import { SplitText } from "gsap/SplitText";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);
const defOptions = {
    bgSVGShow: false,
    bgSVGAnimate: false,
    bgSVGStrokeColor: '#8c00fe'
}


class BgFixedSVG {
    constructor(wrapperElem, options, smoothScroll) {
        this.options = { ...defOptions, ...options }
        this.smoothScroll = smoothScroll
        this.DOM = document.querySelector('.bg-wrp-fixed')
        if (!this.DOM) return

        this.data = {
            totalScrollDistance: undefined,
            scrollProgress: undefined
        }

        if (this.options.bgSVGShow) {
            this.DOM.style.display = 'block'
            this.init()
        } else {
            return
        }

    }
    init = () => {
        this.disableAnimationSmall()
        // this.setScrollDistance()
        this.addEvents()
        this.colorize()
    }

    colorize = () => {
        const path = this.DOM.querySelectorAll('path');
        gsap.set(path, { stroke: this.options.bgSVGStrokeColor })
    }
    animate = () => {
        if (this.options.bgSVGAnimate) {
            gsap.set('.bg-wrp-fixed svg path', {
                drawSVG: `${this.data.scrollProgress * 100}%`
            })
        }
    }
    tick() {
        if (this.options.bgSVGShow && this.options.bgSVGAnimate) {
            const totalScrollDistance = this.smoothScroll.height - window.innerHeight
            this.data.scrollProgress = this.smoothScroll.data.current / totalScrollDistance
            this.animate()
        }
    }

    disableAnimationSmall = () => {
        if (window.innerWidth < 768) {
            this.options.bgSVGAnimate = this.options.bgSVGShow = false
            this.DOM.style.display = "none"
        } else {
            this.options.bgSVGAnimate = this.options.bgSVGShow = true
            this.DOM.style.display = "block"
        }
    }

    addEvents = () => {
        window.addEventListener('resize', this.handleResize)
    }
    handleResize = () => {

        this.disableAnimationSmall()
    }
    destroy = () => {
        window.removeEventListener('resize', this.handleResize)
    }

}

export { BgFixedSVG }