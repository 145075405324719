import './handcrafted.scss';
import { MathUtils } from '../../utils/MathUtils';
import { config } from '../../services/config';

class Handcrafted {
    constructor(elementWrapper, smoothScroll) {
        this.bindMethods();

        this.DOM = { el: elementWrapper }
        if (this.DOM.el) {
            this.DOM.text = this.DOM.el.querySelector('span');
            this.smoothScroll = smoothScroll
            this.renderedStyles = {
                innerTranslationX: {
                    ease: 0.03,
                    current: 0,
                    previous: 0,
                    rounded: 0,
                    // the maximum value to translate the image is set in a CSS variable (--overflow)
                    maxValue: config.winWidth - this.DOM.text.clientWidth,
                    setValue: () => {
                        const maxValue = this.renderedStyles.innerTranslationX.maxValue + config.winWidth * .1;
                        const minValue = -config.winWidth * .1;
                        // const minValue = -1 * maxValue;

                        // Lerp Map x: 
                        //          den Abstand vom oberen Dokumentenrand abzüglich den Scrollfortschritt
                        //          wenn das gleich windows.height, fangen wir an das Bild zu sehen
                        //          a: einfach die Viewport höhe
                        //          b: negative Höhe des Bildwrappers (sichtbares Bild)
                        //          c: mindestWert (vorgegebene Transition negativ 80px)
                        //          d: MaxWert (vorgegebene Transition negativ 80px)
                        //       

                        return Math.max(Math.min(MathUtils.map(this.props.top - smoothScroll.data.target, config.winHeight, -1 * this.props.height, maxValue, minValue), maxValue), minValue)
                        // return Math.max(Math.min(MathUtils.map(this.props.top - smoothScroll.data.current - config.winHeight, 0, -1 * this.props.height - config.winHeight, minValue, maxValue), maxValue), minValue)

                    }
                }
            }
            this.observer = new IntersectionObserver(entries => entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0))
            this.observer.observe(this.DOM.el);
            this.setWrpSize();
            this.update();
            this.addEvents();
        }
    }
    bindMethods() {
        ['resize']
            .forEach((fn) => this[fn] = this[fn].bind(this))
    }
    setWrpSize() {
        this.renderedStyles.innerTranslationX.maxValue = config.winWidth - this.DOM.text.clientWidth;
    }
    update() {
        this.getSize();
        // set initial value without interpolation
        this.renderedStyles.innerTranslationX.current = this.renderedStyles.innerTranslationX.previous = this.renderedStyles.innerTranslationX.setValue()
        // translate the image
        this.layout();
    }
    getSize() {
        if (!this.DOM.el) return;
        const { height, top } = this.DOM.el.getBoundingClientRect();
        this.props = { height, top: this.smoothScroll.data.target + top }

    }
    addEvents() {
        window.addEventListener('resize', this.resize, { passive: true });
    }
    removeEvents() {
        window.removeEventListener('resize', this.resize, { passive: true });
    }
    destroy() {
        if (this.DOM.el) {
            this.renderedStyles.innerTranslationX = null;
            this.renderedStyles = null;
            this.removeEvents()
        }
    }

    resize() {

        config.updateWinsize();
        this.update();
        this.setWrpSize();

    }

    render() {
        // update the current and interpolated Values
        this.renderedStyles.innerTranslationX.current = this.renderedStyles.innerTranslationX.setValue();
        this.renderedStyles.innerTranslationX.previous = MathUtils.lerp(this.renderedStyles.innerTranslationX.previous, this.renderedStyles.innerTranslationX.current, this.renderedStyles.innerTranslationX.ease);
        this.layout();
    }

    layout() {
        this.DOM.text.style.transform = `translate3d(${this.renderedStyles.innerTranslationX.previous}px,0,0)`;
    }
}

export {
    Handcrafted
}