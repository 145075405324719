
// import { SmoothScroll } from '../services/SmoothScroll';
import { ScrollJL } from '../services/ScrollJL';
import { setWrpSizes, SmoothScrollImages } from '../services/smoothScrollImages';
// import {  } from './components/handcrafted';

import { LineSplitText } from '../ui-components/lineSplitText/lineSplitText';
import { MouseFollower } from '../ui-components/mouseFollower/MouseFollower';
import { ScrollDownBtn } from '../ui-components/scrollDownBtn/scrollDownBtn';



import { DragSlider } from '../ui-components/dragSlider/dragSlider';
import { Handcrafted } from '../ui-components/handcrafted/handcrafted';
import { SVGMaskTextBlock } from '../ui-components/SVGMaskTextBlock/SVGMaskTextBlock';
import { SvgStamp } from '../ui-components/svgCircleStamp/svgCircleStamp';
import { MyrImages } from '../ui-components/myrImages/myrImages';
import { BgFixedSVG } from '../ui-components/bgFixedSvg/bgFixedSvg'

// New Elements
import { FullScreenStarter } from '../ui-components/fullScreenStarter/fullScreenStarter'
import { MySplitText } from '../ui-components/split-text/split-text'

const defaultOptions = {
    scrollDownBtnColor: 'white',
    scrollDownBtnShowDelay: 7500,
    bgSVGShow: false,
    bgSVGAnimate: false,
    bgSVGStrokeColor: '#8c00fe'
}

class Page {
    constructor(barbaWrapperElem, options) {
        this.options = { ...defaultOptions, ...options }

        this.wrapperElem = barbaWrapperElem.container;
        this.rAF = undefined
        this.smoothScroll = new ScrollJL(this.wrapperElem);
        this.smoothScroll.off()

        this.handCraftedItem = new Handcrafted(this.wrapperElem.querySelector('.back-fx-exact'), this.smoothScroll);

        this.$smoothImgWrps = [...this.wrapperElem.querySelectorAll('.smoothyImgWrp')]
        this.smoothScrollItems = this.$smoothImgWrps.map(domEl => new SmoothScrollImages(domEl, this.smoothScroll));

        this.$smoothSVGs = [...this.wrapperElem.querySelectorAll('.smoothySVG')]
        this.smoothSVGItems = this.$smoothSVGs.map(domEl => new SVGMaskTextBlock(domEl, this.smoothScroll));

        this.$svgStamps = [...this.wrapperElem.querySelectorAll('.stampWrp')];
        this.svgStampItems = this.$svgStamps.map(item => new SvgStamp(item), this.smoothScroll);

        this.dragSlider = new DragSlider(this.wrapperElem.querySelector('#drag-slider1'))
        this.myrImages = new MyrImages(this.wrapperElem)
        this.ScrollDownBtn = new ScrollDownBtn(this.wrapperElem, {
            scrollDownBtnColor: this.options.scrollDownBtnColor,
            scrollDownBtnShowDelay: this.options.scrollDownBtnShowDelay
        });
        this.bgFixedSVG = new BgFixedSVG(this.wrapperElem, {
            bgSVGShow: this.options.bgSVGShow,
            bgSVGAnimate: this.options.bgSVGAnimate,
            bgSVGStrokeColor: this.options.bgSVGStrokeColor
        }, this.smoothScroll);
        window.BgFixedSVG = this.bgFixedSVG

        this.scrollAnimatedItems = [...this.smoothScrollItems, ...this.smoothSVGItems, this.handCraftedItem]
        this.tickingItems = [...this.smoothScrollItems, ...this.svgStampItems, ...this.smoothSVGItems, this.handCraftedItem, this.dragSlider]

        this.smoothScrollActive = false;


        this.mySplitText = new MySplitText(this.wrapperElem.querySelectorAll('.split-text'));


        // Unused Items Registration
        this.mouseFollower = new MouseFollower([...this.wrapperElem.querySelectorAll('.mouseFollower')])
        this.splitLines = [...this.wrapperElem.querySelectorAll('.splitLine')].map(elem => new LineSplitText(elem))

        this.bindMethods();
        // this.initSmoothScrollItems();
        // this.initLineSplitTextAnimations();
        this.tick();
        this.refreshSmoothScrollItemsPositions();
    }

    bindMethods() {
        ['tick']
            .forEach((fn) => this[fn] = this[fn].bind(this))
    }

    refreshSmoothScrollItemsPositions() {

        this.scrollAnimatedItems.forEach(item => {
            item.getSize()
        })
        // this.bgFixedSVG.setScrollDistance('refresh')
        // console.log(this.smoothScroll.height)
    }

    tick() {
        this.smoothScroll.tick();
        this.tickingItems.forEach((item, index) => {
            if (item.isVisible) {
                item.render()
            }
        })
        this.bgFixedSVG.tick()

        this.mouseFollower.tick();

        this.rAF = window.requestAnimationFrame(this.tick)
    }
    untick() {
        window.cancelAnimationFrame(this.rAF)
        this.rAF = undefined;
    }
    destroy() {
        this.untick();
        this.smoothScroll.destroy();
        this.smoothScrollItems.forEach(item => item.destroy());
        this.mouseFollower.destroy();
        // Unregistered Components
        this.dragSlider.destroy();
        this.handCraftedItem.destroy();
        this.splitLines.forEach(item => item.destroy());
        this.svgStampItems.forEach(item => item.destroy());
        this.myrImages.destroy();
        this.ScrollDownBtn.destroy();
        this.bgFixedSVG.destroy()
    }
};

export { Page };