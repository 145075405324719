const isTouchDevice = () => {
    return window.matchMedia("(pointer:coarse)").matches
}

const updateCopyText = () => {
    const currentYear = new Date().getFullYear()
    const copyTxt = document.querySelector('footer .copy')
    copyTxt.innerHTML = `LIGHT ART STUDIOS ${currentYear}`
}

export { isTouchDevice, updateCopyText }