import { isTouchDevice } from '../../utils/utils'

const ScrollPreventer = class {
    constructor() {
        this.paused = true
        this.scrollTop()
        this.addEvents()
    }

    addEvents = () => {

        window.addEventListener('scroll', this.onScroll)
    }
    removeEvents = () => {
        window.removeEventListener('scroll', this.onScroll)
    }
    disableScroll() {
        this.paused = true
    }
    enableScroll() {
        this.paused = false
    }
    scrollTop = () => {
        window.scrollTo(0, 0)
    }
    onScroll = () => {
        if (isTouchDevice()) return
        if (this.paused) this.scrollTop()
    }
    destroy = () => {
        this.removeEvents()
    }
}

export { ScrollPreventer }