import { gsap } from 'gsap';
// import { SplitText } from "gsap/SplitText";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);


//darkThemeInContent
export default (nextBarbaWrapper) => {

    const pageLogoSvg = nextBarbaWrapper.querySelector('#about-logo');
    const bgImg = nextBarbaWrapper.querySelector('.full-screen-starter .bgImgWrp img')
    const stroke1 = pageLogoSvg.querySelector('#about-logo-stroke1');
    const stroke2 = pageLogoSvg.querySelector('#about-logo-stroke2');
    const letters = [...pageLogoSvg.querySelectorAll('.letter')]

    const tl = gsap.timeline({

    })
        // .set(bgImg, { attr: { src: '/images/about/raphael_grebenstein.jpg' } })
        // .set(bgImg, { delay: .04, scale: .5, attr: { src: '/images/about/ole_guellich.jpg' } })
        //.set(bgImg, { filter: '', delay: .14, attr: { src: '/images/about/abstr1.jpg' } })
        // .set(bgImg, { delay: .13, scale: 1.5, attr: { src: '/images/about/raphael_grebenstein.jpg' } })
        //.set(bgImg, { delay: .12, scale: 1, attr: { src: '/images/about/abstr2.jpg' } })
        // .set(bgImg, { delay: .10, scale: .4, attr: { src: '/images/about/ole_guellich.jpg' } })
        // .set(bgImg, { delay: .07, scale: 1.3, rotate: '-45deg', attr: { src: '/images/about/raphael_grebenstein.jpg' } })
        // .set(bgImg, { delay: .15, scale: .8, rotate: '-120deg', attr: { src: '/images/about/ole_guellich.jpg' } })
        //.set(bgImg, { delay: .15, rotate: '45deg', attr: { src: '/images/about/abstr2.jpg' } })
        .set(bgImg, { delay: .01, filter: '', rotate: 0, scale: 1, transition: 1.5, attr: { src: '/images/about/about_lightartstudios.jpg' } })
        .to(letters, { opacity: 0, duration: 1, stagger: .2, ease: 'linear' })
        .to(stroke1, { drawSVG: 0, duration: 1.75 }, "=-1")
        .to(stroke2, { drawSVG: 0, duration: 1.75 }, "<")
        .to(stroke1, { opacity: 0 })
        .to(stroke2, { opacity: 0 })
        .to('header #logo', { opacity: 1 })

    return tl;

}

