import { MathUtils } from '../../utils/MathUtils';
import { config } from '../../services/config';

const smoothImgWraps = [...document.querySelectorAll('.smoothyImgWrp')];

// Params: Text should be CSS Formatted to 850 px FontFamily Cinzel -> Max Distance is -17% + 17% Origin Text y Offset is 53%


class SvgStamp {
    constructor(el, smoothScroll) {
        this.DOM = { el: el }
        if (!this.DOM.el) return;
        this.DOM.outerStamp = this.DOM.el.querySelector('.stampOuter');
        this.DOM.innerStamp = this.DOM.el.querySelector('.stampInner');

        this.smoothScroll = smoothScroll
        this.baseRotation = 0;
        this.renderedStyles = {
            textRotation: {
                ease: 0.1,
                current: 0,
                previous: 0,
                rounded: 0,

                // the maximum value to translate the image is set in a CSS variable (--overflow)
                // maxValue: parseInt(getComputedStyle(this.DOM.image).getPropertyValue('--overflow'), 10),
                maxValue: undefined,
                setValue: () => {
                    const maxValue = 360;
                    const minValue = 0;
                    return this.baseRotation;
                }
            }
        }



        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this.isVisible = entry.intersectionRatio > 0;

            });

        });
        this.bindMethods();
        this.observer.observe(this.DOM.el);
        // this.setWrpSizeAndOverflow();
        this.update();
        this.addEvents();
        // this.getSize = this.getSize;


        // this.setWrpSizeAndOverflow();
    }
    bindMethods() {
        ['resize']
            .forEach((fn) => this[fn] = this[fn].bind(this))
    }

    update() {
        // this.getSize();
        // set initial value without interpolation
        this.renderedStyles.textRotation.current = this.renderedStyles.textRotation.previous = this.renderedStyles.textRotation.setValue()
        // translate the image
        this.layout();
    }
    getSize() {
        const { height, top } = this.DOM.el.getBoundingClientRect();
        this.props = { height, top: this.smoothScroll.data.current + top }
    }
    addEvents() {
        window.addEventListener('resize', this.resize, { passive: true });
    }
    removeEvents() {
        window.removeEventListener('resize', this.resize, { passive: true });
    }
    destroy() {
        if (!this.DOM.el) return;
        this.renderedStyles.textRotation = null;
        this.renderedStyles = null;
        this.removeEvents()
    }



    resize() {
        // console.log('resize')
        // this.setWrpSizeAndOverflow();
        this.update();

    }

    render() {
        // update the current and interpolated Values
        this.renderedStyles.textRotation.current = this.renderedStyles.textRotation.setValue();
        this.renderedStyles.textRotation.previous = MathUtils.lerp(this.renderedStyles.textRotation.previous, this.renderedStyles.textRotation.current, this.renderedStyles.textRotation.ease);
        this.baseRotation = this.baseRotation + .15;

        this.layout();
    }

    layout() {
        // console.log('layout', this.renderedStyles.textRotation)
        //without siblings
        this.DOM.outerStamp.style.transform = `rotate(${this.renderedStyles.textRotation.previous}deg)`;
        this.DOM.innerStamp.style.transform = `rotate(${-1.3 * this.renderedStyles.textRotation.previous}deg)`;
    }
}

export {
    SvgStamp
}