
class Config {
    constructor() {
        this.winWidth = window.innerWidth;
        this.winHeight = window.innerHeight;
        this.updateWinsize = () => {
            this.winWidth = window.innerWidth;
            this.winHeight = window.innerHeight;
        }
    }
}

const config = new Config();

// const config = {
//     winWidth: window.innerWidth,
//     winHeight: window.innerHeight,
//     updateWinSize() {
//         this.winWidth = window.innerWidth;
//         this.winHeight = window.innerHeight;
//     }
// }

export {
    config
}