import { gsap } from 'gsap';
import { RoughEase } from "gsap/EasePack";
gsap.registerPlugin(RoughEase);
export default (nextBarbaWrapper) => {

    const homeLogoSvg = nextBarbaWrapper.querySelector('.logo');




    gsap.set('header #logo', { display: 'block', opacity: 0 })



    const tl = gsap.timeline({})

        .from(homeLogoSvg, { x: -5, y: 3, opacity: 0, duration: 1, ease: "rough({strength: 8, points: 50, template: strong.inOut, taper: out, randomize: false})" });

    return tl;

}