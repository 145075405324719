


import { isTouchDevice } from '../../utils/utils';
import { MathUtils } from '../../utils/MathUtils'

import { SliderImgParallax } from './sliderImgParallax.js'
const isTouch = isTouchDevice();

class DragSlider {
    constructor(wrapperSelector) {
        this.DOM = { wrapperEl: wrapperSelector }
        if (this.DOM.wrapperEl) {

            this.DOM.container = this.DOM.wrapperEl.querySelector('.drag-planes')
            this.DOM.slides = [... this.DOM.container.querySelectorAll('.drag-plane')]

            this.bounds = { ww: undefined, sw: undefined }
            this.isAnimatedIn = false;
            this.paraImages = []
            this.events = {
                move: isTouch ? 'touchmove' : 'mousemove',
                down: isTouch ? 'touchstart' : 'mousedown',
                up: isTouch ? 'touchend' : 'mouseup'
            }
            this.state = {
                target: {
                    current: 0,
                    final: 0
                },
                pointer: 0,
                pos: 0,
                diff: 0,
                on: 0,
                cancel: {
                    x: 0, y: 0
                },
                max: 0,
                animating: false,
                dragging: false,
                resizing: false,
            }
            this.opts = {
                speed: 1.5,
                ease: 0.075
            }
            this.observer = new IntersectionObserver(entries => entries.forEach(entry => this.isVisible = entry.intersectionRatio > 0))
            this.observer.observe(this.DOM.wrapperEl);

            this.init()
        }
        // testing



    }
    init() {

        this.setSizes();
        this.addEvents();
        this.initParaImages();
    }
    addEvents() {
        const { move, down, up } = this.events
        this.DOM.wrapperEl.addEventListener(up, this.up, { passive: isTouch ? true : false })
        this.DOM.wrapperEl.addEventListener(down, this.down, { passive: isTouch ? true : false })
        this.DOM.wrapperEl.addEventListener(move, this.move, { passive: isTouch ? true : false })
        window.addEventListener('resize', this.resize)
        // window.addEventListener('resize', _.debounce(this.resize, 300))
    }


    removeEvents() {
        const { move, down, up } = this.events
        this.DOM.wrapperEl.removeEventListener(up, this.up)
        this.DOM.wrapperEl.removeEventListener(down, this.down)
        this.DOM.wrapperEl.removeEventListener(move, this.move)
        window.removeEventListener('resize', this.resize)
    }
    destroy() {
        if (this.DOM.wrapperEl) {
            this.removeEvents()
            this.paraImages.forEach(image => image.destroy());
            this.paraImages = []
            this.observer.disconnect();
        }
    }

    setSizes() {
        const bounds = this.bounds;
        bounds.ww = window.innerWidth;
        this.DOM.container.style.position = 'absolute';
        bounds.sw = this.DOM.container.clientWidth;
        this.DOM.container.style.position = '';
        this.state.max = bounds.sw - bounds.ww;
    }

    initParaImages() {
        this.DOM.slides.forEach(element => {
            const paraImg = new SliderImgParallax(element);
            this.paraImages.push(paraImg)
        });
    }

    resize = () => {
        setTimeout(() => { this.setSizes() }, 400)
    }

    // Caching and Helper

    getPosition(e) {
        const x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
        const y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

        return {
            x, y, target: e.target
        }
    }

    // EVENTS
    down = (e) => {

        const { x, y, target } = this.getPosition(e);
        if (!target.closest('.drag-slider')) return;

        const state = this.state;
        state.dragging = true;
        state.animating = true;
        // cancel x y = mousePosition
        state.cancel.x = x;
        state.cancel.y = y;

        // what is state.target? 

        state.on = state.target.final + x * this.opts.speed;
    }

    move = (e) => {
        const state = this.state

        if (!state.dragging) return

        const { cancel } = state
        const { x, y, target } = this.getPosition(e)

        // ??? Was macht das?
        if ((Math.abs(x - cancel.x) > Math.abs(y - cancel.y)) && e.cancelable) {
            e.preventDefault()
            e.stopPropagation()
        }
        // Ausgehend vom runterdrücken, berechnet das die neue Zielposition
        state.target.final = state.on - x * this.opts.speed
        this.clamp(0, state.max, state.final)

    }

    up = () => {
        if (!this.state.dragging) return
        this.state.dragging = false
    }

    clamp() {
        const state = this.state;
        state.target.final = MathUtils.clamp(0, state.max, state.target.final);
    }

    render() {
        const state = this.state;

        if (state.animating) {
            // state.target.current = MathUtils.lerp(state.target.current, state.target.final, this.opts.ease);
            state.target.current = (state.target.final - state.target.current) * this.opts.ease + state.target.current;
            this.DOM.container.style.transform = `translate3d(${-this.state.target.current}px,0,0)`;
            // stop Animating if transition finished
            if (Math.abs(state.target.current - state.target.final) < 0.1 && state.dragging === false) {
                state.animating = false
            }
            this.paraImages.forEach(image => image.render())
        }




    }

}

export { DragSlider }