import './lineSplitText.scss';
import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);


class LineSplitText {
    constructor(domEl) {
        this.DOM = { el: domEl }
        if (this.DOM.el) {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        this.animate();
                        this.observer.unobserve(entry.target)

                    }
                });
            });
            this.observer.observe(this.DOM.el);
            this.splitLines();
        }
    }
    destroy() {
        if (this.DOM.el) {
            this.observer.unobserve(this.DOM.el);
            this.observer = undefined;
        }
    }
    splitLines() {
        new SplitText(this.DOM.el, { type: "lines", linesClass: "outerLine" });
        new SplitText('.splitLine .outerLine', { type: "lines", linesClass: "innerLine" });
        gsap.set('.innerLine', { yPercent: 100 })
    }
    animate() {
        return gsap.timeline()
            .to('.innerLine', { yPercent: 0, duration: 1.75, stagger: .25, ease: "power4.out" })
    }
}

export { LineSplitText }