import { gsap } from 'gsap';

class MyrImages {
    constructor(barbaWrp) {
        this.DOM = { wrps: [...barbaWrp.querySelectorAll('.myrImgWrp')] }

        if (!this.DOM.wrps) return

        this.DOM.lightDummies = document.createDocumentFragment();
        // Observation
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    this.animateIn(entry.target)
                    this.observer.unobserve(entry.target);
                };
            });
        }, { threshold: .5 });
        this.createLights()
    }
    createLights = () => {
        // append 7 Dummies for light Dummies
        this.DOM.wrps.forEach((wrp) => {
            for (let i = 0; i < 7; i++) {
                const light = document.createElement('span');
                light.classList.add(`light${i + 1}`)
                this.DOM.lightDummies.append(light)
            }
            wrp.append(this.DOM.lightDummies)
            this.observer.observe(wrp)
            const lights = wrp.querySelectorAll('span');
            gsap.set(lights, { xPercent: -100 })
        })
    }

    animateIn(targetWrp) {
        const myImg = targetWrp.querySelector('img')
        const lights = targetWrp.querySelectorAll('span');
        const tl = gsap.timeline()
            .to(lights, { x: targetWrp.clientWidth + 50, duration: .75, stagger: .075 })
            .to(myImg, { clipPath: 'polygon(100% 0, 0 0, 0 100%, 100% 100%)', duration: .69 }, "-=.75")
    }
    destroy() {
        this.DOM.wrps = undefined;
        this.DOM.imgMover = undefined;
        this.DOM.lightDummies = undefined;
        this.DOM = undefined;
        this.observer.disconnect()
    }

}

export { MyrImages }