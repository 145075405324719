import { gsap } from 'gsap';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";


import imagesLoaded from 'imagesloaded'

gsap.registerPlugin(DrawSVGPlugin);

//preLoaderAnim
export default {
    in() {
        const windowWidth = window.innerWidth

        const tl = gsap.timeline({
            // onComplete() {
            //     console.log('in is complete')
            // }
        })

            .set('body', { backgroundColor: 'black' })
            .set('#stroke1', { drawSVG: 0, opacity: 0 })
            .set('#stroke2', { drawSVG: 0, opacity: 0 })
            .set('.letters path', { opacity: 0 })
            .set('#canvas-container', {
                autoAlpha: 1,
            })
            .set('.blende-preLoader', {
                autoAlpha: 1,
            })
            .set('.blende-preLoader', {
                yPercent: 0,
            })
            .set('.normal-blend', { x: windowWidth * -.025 })
            .to('body', {
                opacity: 1,
                duration: 0.35
            })
            .set('body', { backgroundColor: 'black' })
        return tl
    },

    out() {

        let checkNaviState = () => !!document.querySelector('.mob-nav-toggler').dataset.navOpen

        const tl = gsap.timeline({

            onComplete() {
                if (!checkNaviState()) {
                }
            }
        })
            .to('#prelLogo .letters path', { opacity: 1, duration: 1, stagger: .1, ease: 'linear' })
            .to('#stroke1', { opacity: 1, drawSVG: '100%', duration: 1.5 })
            .to('#stroke2', { opacity: 1, drawSVG: '100%', duration: 1.5 }, "<")
            .to('.color-blend', {
                x: window.innerWidth,
                duration: 1,
                stagger: {
                    amount: 1,
                    from: "start",
                    ease: 'power3.out'
                }
            })
            .to('.blende-preLoader', {
                duration: 1,
                clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',

            }, "-=1")
            .to('.last-blend', { left: 0 }, "-=.15")
            .set('#canvas-container', {
                autoAlpha: 0,
            })
            .set('.blende-preLoader', {
                autoAlpha: 0,
            })

        return tl;
    }
}