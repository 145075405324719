import { gsap } from 'gsap'


const defaultOptions = {
    scrollDownBtnColor: 'blue',
    scrollDownBtnShowDelay: 8000
}

class ScrollDownBtn {
    constructor(nextBarbaWrapper, options) {
        this.options = { ...defaultOptions, ...options }
        this.DOM = { wrapper: nextBarbaWrapper.querySelector('.scroll-down-btn') }
        if (!this.DOM.wrapper) return
        this.hide()
        this.pauseAnimation()

        setTimeout(this.show, this.options.scrollDownBtnShowDelay)

        this.setBtnColor(this.options.scrollDownBtnColor)
        this.addEvents()

        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    this.animate()
                }
                else {
                    this.pauseAnimation()
                }
            })
        })
        this.observer.observe(this.DOM.wrapper)
    }
    handleScrollDown = () => {
        window.scrollTo(0, window.innerHeight)
    }
    addEvents = () => {
        this.DOM.wrapper.addEventListener('click', this.handleScrollDown)
    }

    setBtnColor(color) {
        this.DOM.wrapper.style.setProperty("--button-color", color);
    }

    hide = () => {
        gsap.set(this.DOM.wrapper, { autoAlpha: 0 })
    }
    show = () => {
        gsap.to(this.DOM.wrapper, { autoAlpha: 1, duration: 2 })
    }
    animate = () => {
        this.DOM.wrapper.classList.remove('paused')
    }
    pauseAnimation = () => {
        this.DOM.wrapper.classList.add('paused')
    }
    destroy = () => {
        if (this.DOM.wrapper) {
            this.observer.disconnect()
            this.DOM.wrapper.removeEventListener('click', this.handleScrollDown)
        }
    }
}

export { ScrollDownBtn }